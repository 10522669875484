import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Grid, Row, Col, Image } from "react-bootstrap"
import StrataDocumentsModal from "./documents/strata-documents-modal"

const IndexPage = ({ data }) => {
  const [currentForm, setCurrentForm] = useState("");
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const strataProposalRequest = 'strataProposalRequest'
  const developerServices = 'developerServices'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'
  const documentOrderFormUrl = 'https://www.cognitoforms.com/KoreckiRealEstateServicesInc/StrataCorporationDocumentOrderFormFormBF'
  const developerServicesUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17'

  return (
    <Layout>
      <SEO title="Korecki Real Estate Services" description="Korecki Real Estate Services provides professional, experienced Strata Management services to clients throughout the Lower Mainland." keywords="korecki,Real Estate,Strata Management services, Strata Property Management, Strata Management, Property Management, vancouver real estate, real estate services, realty services"/>
      <div>
        <div className="parallax-half-section">
          <Image
            alt="Buildings and yachts in downtown vancouver"
            height={800}
            style={{objectFit:"cover"}}
            width="100%"
            src={require('../images/seawall_v2.jpg')}
            className="index-parallax"
          />
        </div>
        <Grid fluid className="half-section-container">
          <Row>
            <Col md={6} className="half-section">
              <div className="parallax-half-section">
                <LazyLoadImage
                  alt="Office workers at a table with their laptops and files"
                  height={400}
                  style={{objectFit:"cover"}}
                  width="100%"
                  src={data.contentfulHomePage.strataManagementImage.file.url}
                />
              </div>
            </Col>
            <Col md={6} className="half-section" style={{backgroundColor: 'rgba(250,250,250,0)'}}>
              <section className="section">
                <div className="col-half-section col-half-section-left">
                  <h1 className="text-uppercase font-weight-bold small-h1">Strata Management</h1>
                  <p></p>
                  {/*<p className="text-md text-color-dark">Meet Porto Finance</p>*/}
                  {/*The following content is not from contentful*/}
                  <p className="custom-text-color-1 mb-xlg">
                    We manage a diverse portfolio of Strata Corporations across the Lower Mainland.
                    Technology and online accessibility form the backbone of our operations, with a personal
                    touch and direct same or next business day communication remaining at the forefront.
                    <br />
                    <br />
                    We utilize standard operating procedures and workflows, to reduce our council’s involvement
                    in mundane activities, allowing them to focus on essential operational tasks, and setting
                    unified long-term goals under the expert guidance of our Agents.
                  </p>
                  <Link to="/strata-management-services" className="btn btn-outline btn-primary-1 text-uppercase margin-right margin-bottom homepage-buttons" >
                    Read More
                  </Link>
                  <button
                    className="btn btn-outline btn-primary-1 text-uppercase margin-bottom"
                    onClick={() => setCurrentForm(strataProposalRequest)}>
                    Strata Management Proposal Request
                    <StrataDocumentsModal
                      open={currentForm===strataProposalRequest}
                      openModal={setCurrentForm}
                      documentUrl={strataProposalRequestUrl}/>
                  </button>
                </div>
              </section>
            </Col>
          </Row>
          <Row className="custom-sm-flex-order">
            <Col md={6} className="half-section" style={{backgroundColor: 'rgba(250,250,250,0)'}}>
              <section className="section">
                <div className="col-half-section col-half-section-left" id="custom">
                  <h1 className="text-uppercase font-weight-bold small-h1">Rental Management</h1>
                  <p></p>
                  <p className="custom-text-color-1 mb-xlg">
                    We manage a diverse portfolio of rental buildings and investment properties across the
                    Lower Mainland. Technology and online accessibility form the backbone of our operations,
                    with a personal touch and direct same business day communication remaining at the forefront.
                    <br />
                    <br />
                    We understand that changing management companies is a significant decision and welcome the
                    opportunity to discuss how we can improve your property operations.
                  </p>
                  <Link to="/rental-management-services" className="btn btn-outline btn-primary-1 text-uppercase margin-right margin-bottom homepage-buttons">
                    Read More
                  </Link>
                  <button
                    className="btn btn-outline btn-primary-1 text-uppercase margin-bottom"
                    onClick={() => setCurrentForm(rentalManagementProposalRequest)}>
                    Rental Management Proposal Request
                    <StrataDocumentsModal
                      open={currentForm===rentalManagementProposalRequest}
                      openModal={setCurrentForm}
                      documentUrl={rentalManagementProposalRequestUrl}/>
                  </button>
                </div>
              </section>
            </Col>
            <Col md={6} className="half-section">
              <div className="parallax-half-section">
                <LazyLoadImage
                  alt="A building with balconies"
                  height={400}
                  style={{objectFit:"cover"}}
                  width="100%"
                  src={data.contentfulHomePage.rentalManagementImage.file.url}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="half-section">
              <div className="parallax-half-section">
                <LazyLoadImage
                  alt="pile of papers"
                  style={{objectFit:"cover"}}
                  height={400}
                  width="100%"
                  src={data.contentfulHomePage.developerServicesImage.file.url}
                />
              </div>
            </Col>
            <Col md={6} className="half-section" style={{backgroundColor: 'rgba(250,250,250,0)'}}>
              <section className="section">
                <div className="col-half-section col-half-section-left">
                  <h1 className="text-uppercase font-weight-bold small-h1">Developer Services</h1>
                  <p className="text-md text-color-dark"></p>
                  <p className="custom-text-color-1 mb-xlg">
                    Your reputation is everything. We understand that a seamless transition from Developer
                    to Strata Corporation creates and maintains a positive Developer image, which informs
                    your future projects and drives sales.
                    <br />
                    <br />
                    We are always at your disposal from initial consult, to preparation of operating budgets,
                    establishing service contracts, and transitioning to the strata corporation. We welcome the
                    opportunity to discuss how we can assist with the execution of your next vision.
                  </p>
                  <Link to="/developers" className="btn btn-outline btn-primary-1 text-uppercase margin-right margin-bottom homepage-buttons">
                    Read More
                  </Link>
                  <button onClick={() => setCurrentForm(developerServices)} className="btn btn-outline btn-primary-1 text-uppercase">
                    <StrataDocumentsModal
                      open={currentForm===developerServices}
                      openModal={setCurrentForm}
                      documentUrl={developerServicesUrl}/>
                    Developer Services Proposal Request
                  </button>
                </div>
              </section>
            </Col>
          </Row>
          <Row className="custom-sm-flex-order">
            <Col md={6} className="half-section" style={{backgroundColor: 'rgba(250,250,250,0)'}}>
              <section className="section">
                <div className="col-half-section col-half-section-left">
                  <h1 className="text-uppercase font-weight-bold small-h1">Strata Form B & F</h1>
                  <p className="text-md text-color-dark"></p>
                  <p className="custom-text-color-1 mb-xlg">
                    { data.contentfulHomePage.strataForm.strataForm }
                  </p>
                  <Link to={documentOrderFormUrl} className="btn btn-outline btn-primary-1 text-uppercase margin-right margin-bottom homepage-buttons">
                    Document Order Form (Form B & F)
                  </Link>
                </div>
              </section>
            </Col>
            <Col md={6} className="half-section">
              <div className="parallax-half-section">
                <LazyLoadImage
                  alt="a person signing a contract"
                  style={{objectFit:"cover"}}
                  height={315}
                  width="100%"
                  src={data.contentfulHomePage.strataFormImage.file.url}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="half-section">
              <div className="parallax-half-section" style={{objectFit:"cover"}}>
                <LazyLoadImage
                  alt="Login page for a website"
                  height={315}
                  width="100%"
                  src={data.contentfulHomePage.loginImage.file.url}
                />
              </div>
            </Col>
            <Col md={6} className="half-section" style={{backgroundColor: '#fafafa'}}>
              <section className="section">
                <div className="col-half-section col-half-section-left">
                  <h1 className="text-uppercase font-weight-bold small-h1">Log In</h1>
                  <p className="text-md text-color-dark"></p>
                  <p className="custom-text-color-1 mb-xlg">
                    { data.contentfulHomePage.logIn.logIn }
                  </p>
                  <a href = {process.env.GATSBY_API_URL+ "/strataLogin"} className="btn btn-outline btn-primary-1 margin-right text-uppercase homepage-buttons">
                    Strata Management Login
                  </a>
                  <a href = {process.env.GATSBY_API_URL+ "/login"} className="btn btn-outline btn-primary-1 text-uppercase">
                    Rental Management Login
                  </a>
                </div>
              </section>
            </Col>
          </Row>
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
      contentfulHomePage(title: {eq: "Korecki Home Page"}) {
      title
      strataManagement {
        strataManagement
      }
      strataManagementImage {
        file {
          url
        }
      }
      rentalManagement {
        rentalManagement
      }
      rentalManagementImage {
        file {
          url
        }
      }
      developerServices {
        developerServices
      }
      developerServicesImage {
        file {
          url
        }
      }
      strataForm {
        strataForm
      }
      strataFormImage {
        file {
          url
        }
      }
      logIn {
        logIn
      }
      loginImage {
        file {
          url
        }
      }
    }
  }`
export default IndexPage
